<template>
	<div class="careers">
		<div class="header-image">
			<h1 class="head-image-text">CAREER OPPORTUNITIES</h1>
		</div>
		<div class="context-container">
			<div class="context-container-opportunity">
				<p v-for="(description, index) in opportunityDescriptions" :key="index" class="context-desc">
					{{ description }}
				</p>
				<p class="context-desc">
					<strong>{{
						careerOpportunities.applicationStatementSection
					}}</strong>
				</p>
			</div>
			<h2 class="context-title">CURRENT OPPORTUNITIES</h2>
			<div class="line"></div>
			<div class="job-container">
				<div class="job-title">
					<div v-for="job in jobs" :key="job.id">
						<div class="opportunity-item">
							<img @click="goToJobWebSite(job.jobUrlLink)" src="./../../../assets/images/icons/pdf/pdf.png" alt="PDF Icon" class="pdf-icon" />
							<span class="divider" />
							<h3
								class="job-title-text"
								@click="goToJobWebSite(job.jobUrlLink)"
							>
								{{ job.jobOpening }}
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCompanyOpportunities } from "../../../services/company";
export default {
	data() {
		return {
			careerOpportunities: null,
			opportunityDescriptions: [],
			jobs: [],
		};
	},
	methods: {
		goToJobWebSite(jobUrlLink) {
			const imageUrlRoute = this.$router.resolve({ path: jobUrlLink });
			window.open(imageUrlRoute.href.slice(1), "_blank");
		},
	},
	mounted() {
		const {
			careerOpportunitiesInformation,
			currentOpenings,
		} = getCompanyOpportunities();
		const {
			descriptionSection,
			applicationStatementSection,
		} = careerOpportunitiesInformation;

		this.opportunityDescriptions = descriptionSection.map(
			({ paragragh }) => paragragh
		);
		this.careerOpportunities = {
			applicationStatementSection,
		};

		this.jobs = currentOpenings;
	},
};
</script>

<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.careers {
	background-color: #fff;
	font-family: 'Inter', sans-serif !important;
	font-weight: 400; 
	color: #040505;
}


.opportunity-item {
	cursor: pointer;
	flex-direction: column;
	display: flex;
	align-items: center; 

	img {
		width: 50px;
		height: 50px;
	}
}
.texture-background {
	margin-bottom: -18px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1; /* Make sure the background stays behind other content */
}
.texture-background::before {
	content: "";
	position: fixed; /* Fixed position */
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1; /* Place it behind the content */
}

.header-image {
	background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_292278205@2x.png") !important;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    width: 100%;
}

.context-container-opportunity {
	display: flex;
	flex-direction: column;

	padding-top: 2vw;
	padding-bottom: 2vw;
	font-size: 2vh;
	text-align: left;
}

@media (max-width: 768px) {
	.header-image {
		background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_292278205.png") !important;
    	height: 300px;
	}

	.context-container-opportunity {
		padding: 5vw;
	}

	.context-desc {
		font-size: 2vh !important;
	}
}
</style>
